.mplus-loading-wrapper {
  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.6);
    z-index: 20;
  }
  &__content {
    background-color: transparent;
    outline: none;
  }
}
